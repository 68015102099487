import happy_man from './happy_man.svg';
import './App.css';
import logo from './logo.svg';
import computer_first from './computer_first.svg';
import computer_second from './computer_second.svg';
import handsome_first from './handsome_first.svg';
import handsome_second from './handsome_second.svg';
import hand_five from './hand_five.svg';
import policy from './pdfs/unicare_policy.pdf';

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <div className="header-content">
                    <img src={logo} className="App-logo" alt="logo" />
                    <div className="header-text">
                        <h1>UniSafe</h1>
                    </div>
                    <nav>
                        <a href="https://unisafe.ru/">О Нас</a>
                        <a href="https://unisafe.ru/contacts/">Контакты</a>
                        <a href="https://unisafe.ru/platron/">Клиентам</a>
                        <a href="https://unisafe.ru/become-a-partner/">Партнерам</a>
                    </nav>
                </div>
            </header>
            <div className="line-after-text"></div>
            <div className="line-with-text">
                <h2>UniCare: Год без хлопот!</h2>
            </div>
            <div className="body-content">
                <div className="left-content">
                    <p><b>Новинка от UniSafe. Услуга «Год без забот»:</b></p>
                    <p>Ваш персональный помощник в решении бытовых задач!</p>
                    <p>Напишите нам, и мы возьмем на себя заботу о вашем доме.</p>
                    <p>От ремонта до уборки, от сантехники до электрики </p>
                    <p>— мы всегда на связи.</p>
                    <p>А ещё мы заботимся о вашей цифровой жизни: установка программ, борьба с вирусами, перенос данных.</p>
                    <p>Гарантируем качество и комфорт на протяжении всего года.</p>
                    <p>Отдайте заботы нам, наслаждайтесь жизнью без хлопот!</p>
                </div>
                <div className="right-content">
                    {/* SVG Image */}
                    {/* Adjust the width of the image as needed */}
                    <img src={happy_man} alt="SVG 1" style={{ width: '70%' }} />
                </div>
            </div>
            <div className="line-after-text"></div>
            <div className="line-with-text">
                <h2>UniCare цифровая забота</h2>
            </div>
            <div className="body-content">
                <div className="right-content">
                    {/* SVG Image */}
                    {/* Adjust the width of the image as needed */}
                    <img src={computer_first} alt="SVG 2" style={{ width: '60%' }} />
                </div>
                <div className="left-content">
                    <p><b>Решение сложностей, связнных с цифровой техникой 24/7</b></p>
                    <p>Включенные сервисы для: смартфонов, планшетов, ноутбуков, стационарных компьютеров:</p>
                    <p>• Активация и перенос данных</p>
                    <p>• Установка и настройка любого программного обеспечения</p>
                    <p>• Устранение вирусов и вредоносного ПО</p>
                    <p>• Любые другие действия свзянные с рпботой цифровых утройств</p>
                </div>
            </div>
            <div className="body-content">
                <div className="left-content">
                    <p><b>Как это работает?</b></p>
                    <p>• Клиент обращается в КЦ UniSafe</p>
                    <p>• Специалист службы заботы определяет всё необходимое для
                        решения задачи</p>
                    <p>• Специалист службы заботы
                        дистанционно подключается к гаджету и решает
                        необходимые задачи</p>
                    <p>• Или направляет специалиста к клиенту</p>
                </div>
                <div className="right-content">
                    {/* SVG Image */}
                    {/* Adjust the width of the image as needed */}
                    <img src={computer_second} alt="SVG 3" style={{ width: '60%' }} />
                </div>
            </div>
            <div className="line-after-text"></div>
            <div className="line-with-text">
                <h2>UniCare бытовая забота</h2>
            </div>
            <div className="body-content">
                <div className="right-content">
                    {/* SVG Image */}
                    {/* Adjust the width of the image as needed */}
                    <img src={handsome_first} alt="SVG 4" style={{ width: '70%' } } />
                </div>
                <div className="left-content">
                <p><b>Решение всех бытовых сложностей 24/7</b></p>
                <p>• Сантехнические работы</p>
                <p>• Электромонтажные работы</p>
                <p>• Мастер на час</p>
                <p>• Слесарные работы</p>
                <p>• Сборка/разборка мебели</p>
                <p>• Подключение и установка бытовой техники</p>
                <p>• Ремонт всех видов дверей и замков</p>
                <p>• Уборка, мытье окон</p>
                <p>• Любый другие виды бытовых услуг</p>

                </div>
            </div>

            <div className="body-content">
                <div className="left-content">
                    <p><b>Как это работает?</b></p>
                    <p>• Клиент обращается в КЦ Unisafe</p>
                    <p>• Специалист службы заботы определяет необходимого специалиста для
                        решения задачи и направляет его клиенту</p>
                    <p>• Бытовая забота оказана с комфортом!</p>
                </div>
                <div className="right-content">
                    {/* SVG Image */}
                    {/* Adjust the width of the image as needed */}
                    <img src={handsome_second} alt="SVG 5" style={{ width: '60%' }} />
                </div>
            </div>

            <div className="line-after-text"></div>

            <div className="body-content">
                <div className="right-content">
                    {/* SVG Image */}
                    {/* Adjust the width of the image as needed */}
                    <img src={hand_five} alt="SVG 6" style={{ width: '70%' } } />
                </div>
                <div className="left-content">
                    <p><b>Как получить услугу?</b></p>
                    <p><b>+7 (800) 555-70-05</b></p>
                    <p><b>help@usafe.ru</b></p>
                    <a href={policy} target="_blank" rel="noopener noreferrer">Пользовательское соглашение</a>
                </div>
            </div>

            <div className="line-after-text"></div>
            <div className="last-text">
                <p>© 2013 — 2023 ООО «ЮниСейф» 121601, г. Москва, Филевский бульвар, д. 6</p>
            </div>

        </div>
    );
}

export default App;
